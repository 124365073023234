import React from "react"
import { Col, Container, Row } from "reactstrap"

const Disclaimer = () => (
  <Container fluid={true} tag="section" id="disclaimer">
    <Container>
    <Row>
      <Col xs="12">
        <strong>Hinweis</strong>
      </Col>
    </Row>
    <Row>
      <Col xs="12" md="10">
        <p>
          (*) Bei den mit einem Stern gekennzeichneten Links handelt es sich um Affiliate-Links
          unserer (Werbe-)Partner.
        </p>
        <p>
          Die Klassifikation und Produktgruppen nach der Taxonomie von Nizza
          wurden den{" "}
          <a
            href="https://www.dpma.de/marken/klassifikation/waren_dienstleistungen/nizza/index.html"
            title="Zum DPMA"
            target="_blank"
          >
            offiziellen Dokumenten des Deutschen Patent- und Markenamts
          </a>{" "}
          entnommen (Stand März 2020).
        </p>
        <p>
          Wir können keinen Anspruch auf Richtigkeit, Vollständigkeit und
          Aktualität unserer Suchergebnisse gewährleisten. Diese Website stellt
          keine Rechtberatung dar und dient lediglich der unverbindlichen
          Erstinformation zu den Themen Markenanmeldung und
          Nizza-Klassifikation.
        </p>
      </Col>
    </Row>
    </Container>
  </Container>
)

export default Disclaimer
