import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import moment from "moment"
import { Container, Col, Row } from "reactstrap"

const Footer: React.FC<any> = props => {

  const rawMenuList = [
    {
      slug: "impressum",
      title: "Impressum",
    },
    {
      slug: "datenschutz",
      title: "Datenschutz",
    },
  ]
  function renderMenuList() {
    return rawMenuList.map(item => (
      <li key={item.slug}>
        <Link to={"/" + item.slug}>{item.title}</Link>
      </li>
    ))
  }

  return (
      <Container tag="footer" fluid={true}>
        <Container>
          <Row className="footer-wrapper">
            <Col xs="12" sm="auto">
              <small>
                <Link to="/">www.nizza-markenklasse.de</Link> &copy;{" "}
                {moment().format("YYYY")}{" "}
              </small>
            </Col>
            <Col xs="12" sm="auto">
              <nav>
                <ul>{renderMenuList()}</ul>
              </nav>
            </Col>
          </Row>
        </Container>
      </Container>
  )
}

export default Footer
