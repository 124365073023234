import Burger from "@animated-burgers/burger-slip"
import "@animated-burgers/burger-slip/dist/styles.css"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useWindowSize } from "../../helper/hooks"
import logo from "./../../images/logo-white.png"

const Header = ({ siteTitle, hideProgress, location }) => {
  const size = useWindowSize()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {size.width < 768 ? (
        <header>
          <div className="nav-wrapper">
            <Link to="/" title="Home">
              <img src={logo} height="26px" />
            </Link>
            <Burger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
          </div>

          {isOpen ? <CustomNav className="mobile" path={location} /> : null}
        </header>
      ) : (
        <header>
          <div className="nav-wrapper">
            <Link to="/" title="Home">
              <img src={logo} height="34x" />
            </Link>

            <CustomNav path={location} />
          </div>
        </header>
      )}
    </>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const CustomNav = ({ className, path }) => {
  const navbarItems: Object[] = [
    {
      text: "Die Klassen",
      title: "Was ist eine Nizza-Klasse?",
      slug: "/",
    },
    {
      text: "Produkte",
      title: "Zu den Produktklassen",
      slug: "/produkte",
    },
    {
      text: "Services",
      title: "Zu den Dienstleistungsklassen",
      slug: "/dienstleistungen",
    },
  ]
  return (
    <nav className={className}>
      <ul>
        {navbarItems.map(item =>
          highlightNavbarItem(item.text, item.title, item.slug, path)
        )}
      </ul>
    </nav>
  )
}

function highlightNavbarItem(
  text: string,
  title: string,
  slug: string,
  path: string
) {
  const active = path === slug
  return (
    <li key={title}>
      <Link to={slug} title={title} className={active ? "active" : ""}>
        {text}
      </Link>
    </li>
  )
}
