/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "@fortawesome/fontawesome-free/js/all.min.js"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { useState } from "react"
import Helmet from "react-helmet"
import "../../style/main.scss"
import Disclaimer from "../content/disclaimer"
import SEO from "../seo/seo"
import StructuredData from "../seo/structured-data"
import Footer from "./footer"
import Header from "./header"

const Layout = ({
  siteTitle,
  children,
  fixed,
  hideProgress,
  seo,
  structuredData,
  location,
  hideDisclaimer,
  hideAdBlock,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <SEO title={siteTitle || data.site.siteMetadata.title} />
      <Helmet>
        <html lang="de" />
        {seo ? { seo } : null}
      </Helmet>

      {structuredData ? <StructuredData {...structuredData} /> : null}

      <Header
        siteTitle={siteTitle || data.site.siteMetadata.title}
        hideProgress={hideProgress}
        location={location}
      />

      <main id="test-wrapper">
        {children}
        {!hideDisclaimer && <Disclaimer></Disclaimer>}
      </main>
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
